import React, { useState } from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import NavDropdown from "./NavDropdown"
import StoryblokLink from "../shared/StoryblokLink"

import { ctaTarget, MenuItemType } from "../../types"

import ArrowIcon from "../../assets/images/icons/dropdown.svg"

type DesktopMenuProps = {
  ctaButton: string
  ctaLink: ctaTarget
  items: MenuItemType[]
  pathname: string
}

const DesktopMenu = ({
  ctaButton,
  ctaLink,
  items,
  pathname = "",
}: DesktopMenuProps) => {
  const [expandedMenuItem, setExpandedMenuItem] = useState(null)

  const renderItemClassNames = (i, groupLink) =>
    classNames("header__menu-label", "hover-bcgUnderline", {
      "header__menu-label--active": expandedMenuItem === i,
      "header__menu-label--activeLink": pathname.includes(groupLink),
    })

  const closeMenu = (e: Event) => {
    if (!(e.target as HTMLElement).classList.contains("header__menu-label")) {
      setExpandedMenuItem(null)
    }
  }

  const menuItems = items?.map((item, i) => {
    let menuItem

    if (item.submenuItems.length > 0) {
      menuItem = (
        <li key={`menuItem-${i}`} className="header__menu-item">
          <span
            className={renderItemClassNames(i, item.link.cached_url)}
            onClick={() =>
              setExpandedMenuItem(expandedMenuItem === i ? null : i)
            }
          >
            {item.label}
            <ArrowIcon className="header__menu-itemIcon ml-2" />
          </span>
          <NavDropdown
            isExpanded={expandedMenuItem === i}
            closeDropdown={closeMenu}
            items={item.submenuItems}
            button={item.button}
            pathname={pathname}
          />
        </li>
      )
    } else {
      menuItem = (
        <li key={`menuItem-${i}`} className="header__menu-item">
          <StoryblokLink
            to={item.link}
            className="hover-bcgUnderline"
            pathname={pathname}
          >
            {item.label}
          </StoryblokLink>
        </li>
      )
    }

    return menuItem
  })

  return (
    <div className="header__navigation d-none d-xl-flex align-items-center">
      <ul className="header__menu d-flex">{menuItems}</ul>

      {ctaButton && ctaLink.cached_url && (
        <StoryblokLink to={ctaLink} className="btn-primary">
          {ctaButton}
        </StoryblokLink>
      )}
    </div>
  )
}

export default DesktopMenu
