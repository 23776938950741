import React from "react"

import Layout from '../components/layout/Layout'
import Hero from '../components/shared/Hero'
import TopThreeBlock from '../components/shared/TopThreeBlock'
import DataAssessment from '../components/services/DataAssessment'
import DataCuration from '../components/services/DataCuration'
import DataScience from '../components/services/DataScience'
import CtaBanner from '../components/shared/CtaBanner'
import Roadmap from '../components/services/Roadmap';

import { CtaBannerType, HeaderType, IndustriesCardType, SeoMetaType, ServiceCardType, StoryblokImageType, } from '../types'

type ServicesOverviewProps = {
  seoMeta: SeoMetaType
  pathname: string
  header: HeaderType[]
  servicesOfferingTitle: string
  servicesOfferingText: string
  servicesOfferingCards: IndustriesCardType[]
  dataAssessmentTitle: string
  dataAssessmentText: string
  dataAssessmentCards: ServiceCardType[]
  dataAssessmentImage: StoryblokImageType
  dataCurationTitle: string
  dataCurationText: string
  dataCurationCards: ServiceCardType[]
  analyticsTitle: string
  analyticsText: string
  analyticsCards: ServiceCardType[]
  ctaBanner: CtaBannerType
  roadmapTitle: string;
  roadmapSteps: { text: string }[];
}

const ServicesOverview = ({
  seoMeta,
  pathname,
  header,
  servicesOfferingTitle,
  servicesOfferingText,
  servicesOfferingCards,
  dataAssessmentTitle,
  dataAssessmentText,
  dataAssessmentCards,
  dataAssessmentImage,
  dataCurationTitle,
  dataCurationText,
  dataCurationCards,
  analyticsTitle,
  analyticsText,
  analyticsCards,
  ctaBanner,
  roadmapTitle,
  roadmapSteps
}: ServicesOverviewProps) => {
  return (
    <Layout
      contentClass="ServicesOverviewPage"
      seoMeta={seoMeta}
      pathname={pathname}
    >
      <Hero header={header} />
      <TopThreeBlock
        title={servicesOfferingTitle}
        text={servicesOfferingText}
        cards={servicesOfferingCards}
      />
      <Roadmap
       steps={roadmapSteps}
       title={roadmapTitle}
      />
      <DataAssessment
        title={dataAssessmentTitle}
        text={dataAssessmentText}
        cards={dataAssessmentCards}
        image={dataAssessmentImage}
      />
      <DataCuration
        title={dataCurationTitle}
        text={dataCurationText}
        cards={dataCurationCards}
      />
      <DataScience
        title={analyticsTitle}
        text={analyticsText}
        cards={analyticsCards}
      />
      <CtaBanner ctaBanner={ctaBanner} />
    </Layout>
  )
}

export default ServicesOverview
