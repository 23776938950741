import React, { useState } from "react"
import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"

import { useIsMenuFloating } from "../hooks/useIsMenuFloating"

import DesktopMenu from "./DesktopMenu"
import MobileMenu from "./MobileMenu"
import StoryblokImage from "../shared/StoryblokImage"
import StoryblokLink from "../shared/StoryblokLink"

type HeaderProps = {
  pathname: string
  hideNavigation?: boolean;
}

const useHeaderDataFromStoryblok = () => {
  const queryResult = useStaticQuery(
    graphql`
      query {
        storyblokEntry(field_component: { eq: "top-navigation" }) {
          content
        }
      }
    `
  )

  return queryResult.storyblokEntry
    ? JSON.parse(queryResult.storyblokEntry.content)
    : undefined
}

const Header = ({ pathname, hideNavigation }: HeaderProps) => {
  const [isMenuVisible, setMenuVisibility] = useState(false)
  let isMenuFloating = useIsMenuFloating()
  if (hideNavigation) {
    isMenuFloating = false;
  }
  const content = useHeaderDataFromStoryblok()

  const renderClassNames = () =>
    classNames("header", {
      "header--floating": isMenuFloating,
      "header--opened": isMenuVisible
    })

  const renderHamburgerClassNames = () =>
    classNames("d-xl-none", "header__hamburger", {
      "header__hamburger--open": isMenuVisible
    })

  return (
    <header className={renderClassNames()}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <section className="header__content d-flex align-items-center">
              <StoryblokLink to={content?.logoLink}>
                <StoryblokImage
                  image={
                    isMenuFloating || isMenuVisible
                      ? content?.logoDark
                      : content?.logo
                  }
                  className="header__logo"
                />
              </StoryblokLink>

              {!hideNavigation && (
                <>
                  <DesktopMenu
                    pathname={pathname}
                    ctaButton={content?.ctaButton}
                    ctaLink={content?.ctaLink}
                    items={content?.menuItems}
                  />

                  <div
                    className={renderHamburgerClassNames()}
                    onClick={() => setMenuVisibility(!isMenuVisible)}
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                </>
              )}
            </section>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            {!hideNavigation &&
            <MobileMenu
              isMenuVisible={isMenuVisible}
              ctaButton={content?.ctaButton}
              ctaLink={content?.ctaLink}
              items={content?.menuItems}
              pathname={pathname}
            />}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
