import React, { useRef } from "react"

import ResourceSlider from "../resources/ResourceSlider"

import PrevArrow from "../../assets/images/icons/prev-arrow-resource.svg"
import NextArrow from "../../assets/images/icons/next-arrow-resource.svg"

import {
  ThreeCardCaseStudySelection,
  ThreeCardReportSelection,
} from "../../types"

type ResourceContentType = {
  cards: ThreeCardCaseStudySelection[] | ThreeCardReportSelection[]
  isCaseStudy: boolean
  isReport: boolean
  isWebinar: boolean
  ctaText: string
}

const ResourceContent = ({
  cards,
  isCaseStudy,
  isReport,
  isWebinar,
  ctaText,
}: ResourceContentType) => {
  const sliderRef = useRef(null)

  const showNext = () => {
    sliderRef.current.slickNext()
  }

  const showPrev = () => {
    sliderRef.current.slickPrev()
  }

  return (
    <div className="c-resourceContent">
      <ResourceSlider
        cards={cards}
        isCaseStudy={isCaseStudy}
        isReport={isReport}
        isWebinar={isWebinar}
        ctaText={ctaText}
        forwardedRef={sliderRef}
      />
      <div className="c-resourceContent__buttons d-flex justify-content-end">
        <button onClick={showPrev} className="c-resourceContent__button">
          <div className="c-resourceContent__hoverButton d-flex justify-content-center align-items-center">
            <PrevArrow />
          </div>
        </button>

        <button onClick={showNext} className="c-resourceContent__button">
          <div className="c-resourceContent__hoverButton d-flex justify-content-center align-items-center">
            <NextArrow />
          </div>
        </button>
      </div>
    </div>
  )
}

export default ResourceContent
