import * as React from "react"

import NotFound from "../component-not-found"
import ThreeBlockBlog from "../shared/ThreeBlockBlog"
import ThreeBlockWhitepaper from "../shared/ThreeBlockWhitepaper"
import ThreeBlockCaseStudy from "../shared/ThreeBlockCaseStudy"

const Components = type => {
  const ComponentList = {
    Blog: ThreeBlockBlog,
    Whitepaper: ThreeBlockWhitepaper,
    "Case Study": ThreeBlockCaseStudy,
  }

  if (typeof ComponentList[type] === "undefined") {
    return NotFound
  }
  return ComponentList[type]
}

export default Components
