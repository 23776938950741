import React from "react"
import classNames from "classnames"

import ThreeBlockComponents from "../componentKeys/threeBlockComponents"
import StoryblokLink from "../shared/StoryblokLink"

import heroPatternDark from "../../assets/images/hero-pattern-dark.svg"

import { ThreeCard, ctaTarget } from "../../types"

type ThreeBlockProps = {
  title: string
  text?: string
  cards: ThreeCard[]
  cta?: string
  ctaLink: ctaTarget
  resourcesType?: boolean
}

const ThreeBlock = ({
  title,
  text,
  cards,
  cta,
  ctaLink,
  resourcesType,
}: ThreeBlockProps) => {
  const blockItemClasses = () =>
    classNames("c-threeBlock__column col-xl-4 mb-sm-5 mb-xl-0", {
      "col-lg-6": !resourcesType,
      "col-md-6": resourcesType,
    })

  const blockItems = cards?.map((card, i) => (
    <div key={`card-item-${i}`} className={blockItemClasses()}>
      {React.createElement(ThreeBlockComponents(card?.content.component), {
        ...card,
      })}
    </div>
  ))

  const sectionClasses = () =>
    classNames("c-threeBlock", {
      "c-threeBlock--resources": resourcesType,
    })

  return (
    <section className={sectionClasses()}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-threeBlock__box">
              <div className="row mb-5">
                <div className="col-12 mb-2 mb-sm-3">
                  <h2 className="section-heading">{title}</h2>
                </div>
              </div>

              {text && (
                <div className="row mb-0 mb-lg-3 mb-xxl-3">
                  <div className="col-12 pb-0 pb-xl-4 pb-xxl-0">
                    <h3 className="section-subheading">{text}</h3>
                  </div>
                </div>
              )}

              <div className="c-threeBlock__items">
                <div className="row">{blockItems}</div>
              </div>
            </div>

            {cta && ctaLink.cached_url && (
              <StoryblokLink
                to={ctaLink}
                className="c-threeBlock__sectionLink btn-tertiary hover-bcgUnderline"
              >
                {cta}
              </StoryblokLink>
            )}
          </div>
        </div>
      </div>

      <img src={heroPatternDark} className="c-threeBlock__pattern" alt="" />
    </section>
  )
}

export default ThreeBlock
