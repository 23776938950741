import React from "react"
import { Helmet } from "react-helmet"

import { SeoMetaType } from "../../types"

const seoMeta = ({
  title,
  description,
  ogImage,
  canonicalLink,
  noIndex,
}: SeoMetaType) => {
  const meta = []
  const link = []

  if (title) {
    meta.push({ name: `og:title`, content: title })
  }

  if (description) {
    meta.push({ name: `description`, content: description })
    meta.push({ name: `og:description`, content: description })
  }

  if (canonicalLink) {
    meta.push({ name: `og:url`, content: canonicalLink })
    link.push({ rel: "canonical", href: canonicalLink })
  }

  if (ogImage) {
    meta.push({ name: `og:image`, content: ogImage })
  }

  if (noIndex) {
    meta.push({ name: "robots", content: "noindex" })
  }

  return (
    <>
      <Helmet title={title} meta={meta} link={link} />
    </>
  )
}

export default seoMeta
