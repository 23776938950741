import { useZohoFormLogic, ZohoFormFields } from "../components/zohoForms/zohoLogic"

const NEWSLETTER_SIGN_UP_SUBMIT_URL =
  "https://forms.zohopublic.eu/simplitygroup/form/SimplityNewslettersignup/formperma/eFTSqJjwb_lw4wMEjQAhj9_-2xvIYVGvYar_vXOeFKg/htmlRecords/submit"

const NEWSLETTER_THANK_YOU_URL = '/get-in-touch/thank-you-for-newsletter-subscription/';

type NewsletterSignupFields = {
  email: string;
}

const zohoNewsletterFormFields: ZohoFormFields<NewsletterSignupFields> = {
  email: {
    zohoFieldName: "Email",
    initialValue: "",
    required: true,
    runEmailValidation: true
  }
}

export const useNewsletterSignup = (formIdentifier: string) => {
  const {
    values,
    setValue,
    doSubmit,
    isFetching
  } = useZohoFormLogic<NewsletterSignupFields>({
    formIdentifier: formIdentifier,
    zohoSubmitUrl: NEWSLETTER_SIGN_UP_SUBMIT_URL,
    zohoFormFields: zohoNewsletterFormFields,
    successRedirectUrl: NEWSLETTER_THANK_YOU_URL,
  })

  const email = values.email
  const setEmail = (newEmail: string) => setValue("email", newEmail)

  return {
    email,
    setEmail,
    doSubmit,
    isFetching
  }
}
