import React from "react"
import classNames from "classnames"
import { render } from "storyblok-rich-text-react-renderer"

import StoryblokLink from "./StoryblokLink"

import { renderHeadline } from "../contentEdit"
import DecorationComponents from "../componentKeys/decorationHeroImages"

import { HeaderType } from "../../types"

type HeroProps = {
  header: HeaderType[]
}

const Hero = ({ header }: HeroProps) => {
  const title = renderHeadline(header[0]?.pageTitle, "c-hero__headline")
  const text = render(header[0]?.introParagraph)

  const renderClasses = () =>
    classNames("c-hero", {
      "c-hero--imageVertical": header[0]?.imageSize === "663x990",
      "c-hero--imageVerticalBig": header[0]?.imageSize === "805x994",
      "c-hero--imageHorizontalCommon": header[0]?.imageSize === "944x620",
      "c-hero--imageHorizontalStudy":
        header[0]?.imageSize === "944x620CaseStudy",
      "c-hero--imageHorizontalSmall": header[0]?.imageSize === "802x620",
      "c-hero--imageHorizontalMedium": header[0]?.imageSize === "941x705",
      "c-hero--imageHorizontalLarge": header[0]?.imageSize === "1083x620",
    })

  const renderColumnClasses = () =>
    classNames("col-md-10 col-lg-8 col-xl-6", {
      "col-xxl-5": header[0]?.imageSize !== "802x620",
    })

  const decorationImage = header[0]?.imageSize
    ? React.createElement(DecorationComponents(header[0]?.imageSize), {
        key: header[0]?.imageSize,
        image: header[0].image.filename,
      })
    : null

  return (
    <section className={renderClasses()}>
      <div className="container">
        <div className="row">
          <div className={renderColumnClasses()}>
            <div className="c-hero__content">
              {title}
              <div className="c-hero__text">{text}</div>
              {header[0]?.ctaLink && header[0]?.ctaButton && (
                <StoryblokLink
                  to={header[0]?.ctaLink}
                  isInternalAnchor={
                    header[0]?.ctaLink.cached_url.charAt(0) === "#"
                  }
                  className="d-inline-block mt-5"
                >
                  <span className="btn-secondary">{header[0]?.ctaButton}</span>
                </StoryblokLink>
              )}
            </div>
          </div>
        </div>
      </div>

      {decorationImage}
    </section>
  )
}

export default Hero
