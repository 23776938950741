import React from "react"

import Layout from "../components/layout/Layout"
import Hero from "../components/shared/Hero"
import ThreeBlock from "../components/shared/ThreeBlock"

import { SeoMetaType, HeaderType, ThreeCard } from "../types"

type ThankYouProps = {
  seoMeta: SeoMetaType
  header: HeaderType[]
  learnMoreTitle: string
  cards: ThreeCard[]
  pathname: string
}

const ThankYou = ({
  seoMeta,
  header,
  learnMoreTitle,
  cards,
  pathname,
}: ThankYouProps) => {
  return (
    <Layout contentClass="ThankYouPage" seoMeta={seoMeta} pathname={pathname}>
      <Hero header={header} />
      <ThreeBlock title={learnMoreTitle} cards={cards} />
    </Layout>
  )
}

export default ThankYou
