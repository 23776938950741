import React from "react"
import Layout from "../components/layout/Layout"
import Hero from "../components/shared/Hero"
import ThreeBlock from "../components/shared/ThreeBlock"
import CtaBanner from "../components/shared/CtaBanner"
import ResourceListing from "../components/resources/ResourceListing"

import {
  SeoMetaType,
  HeaderType,
  ThreeCard,
  ctaTarget,
  CtaBannerType,
  ThreeCardCaseStudySelection,
  ThreeCardReportSelection,
} from "../types"

type ResourcesPageType = {
  seoMeta: SeoMetaType
  pathname: string
  header: HeaderType[]
  blogTitle: string
  blogText: string
  blogCards: ThreeCard[]
  blogCta: string
  blogCtaLink: ctaTarget
  ctaBanner: CtaBannerType
  caseStudiesTitle: string
  caseStudiesText: string
  caseStudiesCards: ThreeCardCaseStudySelection[]
  caseStudiesCtaText: string
  reportsTitle: string
  reportsText: string
  reportsCards: ThreeCardReportSelection[]
  reportsCtaText: string
}

const ResourcesPage = ({
  seoMeta,
  pathname,
  header,
  blogTitle,
  blogText,
  blogCards,
  blogCta,
  blogCtaLink,
  ctaBanner,
  caseStudiesTitle,
  caseStudiesText,
  caseStudiesCards,
  caseStudiesCtaText,
  reportsTitle,
  reportsText,
  reportsCards,
  reportsCtaText,
}: ResourcesPageType) => {
  return (
    <Layout contentClass="ResourcesPage" seoMeta={seoMeta} pathname={pathname}>
      <Hero header={header} />
      <ThreeBlock
        title={blogTitle}
        text={blogText}
        cards={blogCards}
        cta={blogCta}
        ctaLink={blogCtaLink}
        resourcesType
      />
      <ResourceListing
        isReport
        title={reportsTitle}
        text={reportsText}
        cards={reportsCards}
        ctaText={reportsCtaText}
      />
      <ResourceListing
        isCaseStudy
        title={caseStudiesTitle}
        text={caseStudiesText}
        cards={caseStudiesCards}
        ctaText={caseStudiesCtaText}
      />
      <CtaBanner ctaBanner={ctaBanner} />
    </Layout>
  )
}

export default ResourcesPage
