import React from 'react'
import classNames from 'classnames'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../../assets/styles/start.scss'

import Header from './Header'
import Footer from './Footer'
import SeoMeta from './SeoMeta'

import { SeoMetaType } from '../../types'
import { useUtmTracking } from '../../cookieLogic/utmTrackingLogic'

type LayoutProps = {
  children: React.ReactNode
  contentClass?: string
  seoMeta: SeoMetaType
  errorPage?: boolean
  pathname: string
  hideNavigation?: boolean
}

const Layout = ({
                  children,
                  contentClass,
                  seoMeta,
                  errorPage,
                  pathname,
                  hideNavigation,
                }: LayoutProps) => {
  useUtmTracking();
  const renderPageClassNames = () =>
    classNames('layout', {
      [`${contentClass}`]: !!contentClass,
    });

  return (
    <div className={renderPageClassNames()}>
      <SeoMeta {...seoMeta} />
      <Header pathname={pathname} hideNavigation={hideNavigation}/>
      <main className="main">{children}</main>
      <Footer errorPage={errorPage} hideNavigation={hideNavigation}/>
    </div>
  )
};

export default Layout
