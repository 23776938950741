import React from "react"
import classNames from "classnames"

import StoryblokImage from "../shared/StoryblokImage"

import { ServiceCardType } from "../../types"

type SolutionServiceCardProps = {
  card: ServiceCardType
  classModifier: string
}

const SolutionServiceCard = ({
  card,
  classModifier,
}: SolutionServiceCardProps) => {
  return (
    <div
      className={`c-solutionServices__card ${classModifier} d-flex flex-column align-items-start`}
    >
      <div className="c-solutionServices__imageWrapper">
        <StoryblokImage image={card?.content.iconWhite} />
      </div>

      <h4 className="c-solutionServices__title">{card?.content.title}</h4>

      <p className="c-solutionServices__text">{card?.content.shortText}</p>
    </div>
  )
}

export default SolutionServiceCard
