import React from "react"

import StoryblokLink from "../shared/StoryblokLink"

import { renderCardHeadline } from "../contentEdit"

import { ThreeCardCaseStudySelection } from "../../types"

type ResourceCaseStudyCardProps = {
  content: ThreeCardCaseStudySelection
  ctaText: string
}

const ResourceCaseStudyCard = ({
  content,
  ctaText,
}: ResourceCaseStudyCardProps) => {
  const linkObject = {
    cached_url: content?.full_slug,
    linktype: "story",
  }

  const cardHeadline = renderCardHeadline(
    content?.content.header[0].pageTitle,
    "c-resourceCaseStudyCard__heading"
  )

  return (
    <StoryblokLink to={linkObject} className="c-resourceCaseStudyCard">
      <div className="c-resourceCaseStudyCard__hoverWrapper">
        <div
          className="c-resourceCaseStudyCard__imageWrapper"
          style={{
            backgroundImage: `url(${content?.content.header[0].cardThumb.filename})`,
          }}
        />

        <div className="c-resourceCaseStudyCard__content">
          <p className="c-resourceCaseStudyCard__type">
            {content?.content.component}
          </p>

          {cardHeadline}

          <span className="btn-tertiary hover-bcgUnderline">{ctaText}</span>
        </div>
      </div>
    </StoryblokLink>
  )
}

export default ResourceCaseStudyCard
