import React, { useCallback } from "react"
import classNames from "classnames"

import StoryblokLink from "../shared/StoryblokLink"

import DropdownComponents from "../componentKeys/dropDownComponents"

import { useClickOutsideListenerRef } from "../hooks/useClickOutsideListenerRef"

import { SubmenuItem } from "../../types"

type NavDropdownProps = {
  isExpanded: boolean
  closeDropdown: (e: Event) => void
  items: SubmenuItem[]
  button?: SubmenuItem[]
  pathname: string
}

const NavDropdown = ({
  isExpanded,
  closeDropdown,
  items,
  button,
  pathname,
}: NavDropdownProps) => {
  const renderClassNames = () =>
    classNames("header__dropdown", "flex-column", {
      "d-flex": isExpanded,
      "header__dropdown--expanded": isExpanded,
    })

  const onClickOutsideCallback = useCallback(
    (e: Event) => {
      if (isExpanded) {
        closeDropdown(e)
      }
    },
    [closeDropdown, isExpanded]
  )

  const ref = useClickOutsideListenerRef(onClickOutsideCallback)

  const menuItems = items?.map((item, i) =>
    React.createElement(DropdownComponents(item.component), {
      key: i,
      ...item,
      pathname: pathname,
    })
  )

  const menuButton = button.length ? (
    <li className="header__dropdown-item">
      <StoryblokLink
        to={button[0].link}
        className="btn-tertiary hover-bcgUnderline"
      >
        {button[0].label}
      </StoryblokLink>
    </li>
  ) : null

  return (
    <div className={renderClassNames()} ref={ref}>
      <ul className="header__dropdown-content">
        {menuItems}
        {menuButton}
      </ul>
    </div>
  )
}

export default NavDropdown
