import React from "react"

import Layout from "../components/layout/Layout"
import Hero from "../components/shared/Hero"
import Clients from "../components/home/Clients"
import UseCases from "../components/home/UseCases"
import Learn from "../components/shared/Learn"
import Testimonials from "../components/home/Testimonials"
import CtaBanner from "../components/shared/CtaBanner"

import {
  HeaderType,
  SeoMetaType,
  ClientsLogos,
  Card,
  Resource,
  Testimonial,
  CtaBannerType,
} from "../types"

type HomePageProps = {
  header: HeaderType[]
  seoMeta: SeoMetaType
  title: string
  text: string
  logos: ClientsLogos[]
  titleUseCases: string
  textUseCases: string
  useCaseCards: Card[]
  titleLearn: string
  textLearn: string
  resource: Resource[]
  testimonials: Testimonial[]
  ctaBanner: CtaBannerType
  pathname: string
}

function HomePage({
  header,
  seoMeta,
  title,
  text,
  logos,
  titleUseCases,
  textUseCases,
  useCaseCards,
  titleLearn,
  textLearn,
  resource,
  testimonials,
  ctaBanner,
  pathname,
}: HomePageProps) {
  return (
    <Layout contentClass="Homepage" seoMeta={seoMeta} pathname={pathname}>
      <Hero header={header} />
      <Clients title={title} text={text} logos={logos} />
      <UseCases
        title={titleUseCases}
        text={textUseCases}
        cards={useCaseCards}
      />
      <Learn title={titleLearn} text={textLearn} resource={resource} />
      {/* <Testimonials testimonials={testimonials} /> */}
      <CtaBanner ctaBanner={ctaBanner} />
    </Layout>
  )
}

export default HomePage
