import React, { ReactNode } from "react"
import Slider from "react-slick"

import ResourceCaseStudyCard from "./ResourceCaseStudyCard"
import ResourceWhitepaperCard from "./ResourceWhitepaperCard"

import {
  ThreeCardCaseStudySelection,
  ThreeCardReportSelection,
} from "../../types"

type ResourceSliderProps = {
  cards: ThreeCardCaseStudySelection[] | ThreeCardReportSelection[]
  isCaseStudy: boolean
  isReport: boolean
  isWebinar: boolean
  ctaText: string
  forwardedRef: React.Ref<ReactNode>
}

const ResourceSlider = ({
  cards,
  isCaseStudy,
  isReport,
  isWebinar,
  ctaText,
  forwardedRef,
}: ResourceSliderProps) => {
  const settings = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    dots: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const slides = cards?.map((card, i) => (
    <React.Fragment key={`resource-card-${i}`}>
      {isCaseStudy && (
        <ResourceCaseStudyCard
          key={`resource-case-card-${i}`}
          content={card}
          ctaText={ctaText}
        />
      )}
      {isReport && (
        <ResourceWhitepaperCard
          key={`resource-whitepaper-card-${i}`}
          content={card}
          ctaText={ctaText}
        />
      )}
    </React.Fragment>
  ))

  return (
    <div className="c-resourceSlider">
      <Slider ref={forwardedRef} {...settings}>
        {slides}
      </Slider>
    </div>
  )
}

export default ResourceSlider
