import React from "react"

import Layout from "../components/layout/Layout"
import Hero from "../components/shared/Hero"
import Benefits from "../components/solutionDetail/Benefits"
import Services from "../components/solutionDetail/Services"
import Learn from "../components/shared/Learn"
import Testimonials from "../components/home/Testimonials"
import ThreeBlock from "../components/shared/ThreeBlock"
import CtaBanner from "../components/shared/CtaBanner"

import {
  SeoMetaType,
  HeaderType,
  BenefitCardType,
  ServiceCardType,
  Resource,
  Testimonial,
  CtaBannerType,
  ThreeCard,
} from "../types"

type SolutionDetailProps = {
  seoMeta: SeoMetaType
  pathname: string
  header: HeaderType[]
  benefitsTitle: string
  benefitsText: string
  benefitsCards: BenefitCardType[]
  servicesTitle: string
  servicesText: string
  servicesList: ServiceCardType[]
  caseStudyTitle: string
  caseStudyText: string
  caseStudy: Resource[]
  testimonials: Testimonial[]
  learnMoreTitle: string
  resources: ThreeCard[]
  ctaBanner: CtaBannerType
}

const SolutionDetail = ({
  seoMeta,
  pathname,
  header,
  benefitsTitle,
  benefitsText,
  benefitsCards,
  servicesTitle,
  servicesText,
  servicesList,
  caseStudyTitle,
  caseStudyText,
  caseStudy,
  testimonials,
  ctaBanner,
  learnMoreTitle,
  resources,
}: SolutionDetailProps) => {
  return (
    <Layout
      contentClass="SolutionDetailPage"
      seoMeta={seoMeta}
      pathname={pathname}
    >
      <Hero header={header} />
      <Benefits
        title={benefitsTitle}
        text={benefitsText}
        cards={benefitsCards}
      />
      <Services
        title={servicesTitle}
        text={servicesText}
        items={servicesList}
      />
      {/* <Learn title={caseStudyTitle} text={caseStudyText} resource={caseStudy} /> */}
      {/* <Testimonials testimonials={testimonials} /> */}
      <ThreeBlock title={learnMoreTitle} cards={resources} />
      <CtaBanner ctaBanner={ctaBanner} />
    </Layout>
  )
}

export default SolutionDetail
