import * as React from "react"

export const useIsMenuFloating = () => {
  const [isMenuFloating, setIsMenuFloating] = React.useState(false)
  const isMenuFloatingRef = React.useRef(isMenuFloating)

  React.useEffect(() => {
    isMenuFloatingRef.current = isMenuFloating
  }, [isMenuFloating])

  const onScroll = React.useCallback(() => {
    const shouldBeFloating = window.pageYOffset > 0
    if (shouldBeFloating != isMenuFloatingRef.current) {
      setIsMenuFloating(shouldBeFloating)
    }
  }, [])

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return isMenuFloating
}
