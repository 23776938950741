import { useCallback, useEffect, useRef } from "react"

export const useClickOutsideListenerRef = (
  onOutsideClick?: (e: Event) => void
) => {
  const ref = useRef(null)

  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onOutsideClick?.(e)
      }
    },
    [onOutsideClick]
  )

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (!(ref.current! as any).contains(e.target)) {
        onOutsideClick?.(e)
      }
    },
    [ref.current, onOutsideClick]
  )

  useEffect(() => {
    document.addEventListener("click", clickListener)
    document.addEventListener("keyup", escapeListener)
    return () => {
      document.removeEventListener("click", clickListener)
      document.removeEventListener("keyup", escapeListener)
    }
  }, [clickListener, escapeListener])
  return ref
}
