import ComponentNotFound from "./component-not-found"

import HomePage from "../simplityPages/HomePage"
import GetInTouchPage from "../simplityPages/GetInTouchPage"
import ThankYouPage from "../simplityPages/ThankYouPage"
import Page404 from "../simplityPages/Page404"
import SolutionsOverview from "../simplityPages/SolutionsOverview"
import SolutionDetail from "../simplityPages/SolutionDetail"
import ServicesOverview from "../simplityPages/ServicesOverview"
import AboutUs from "../simplityPages/AboutUs"
import BlogPost from "../simplityPages/BlogPost"
import CaseStudyDetail from "../simplityPages/CaseStudyDetail"
import ResourcesOverview from "../simplityPages/ResourcesPage"
import CtaNewsletterBanner from "./shared/CtaNewsletterBanner";
import EuCoFundedProjects from "../simplityPages/EuCofundedProjects"
import SimplityEuropeHomepage from "../simplityPages/SimplityEuropeHomepage"

const ComponentList = {
  ["Home"]: HomePage,
  ["Get In Touch"]: GetInTouchPage,
  ["Thank You"]: ThankYouPage,
  ["Error 404"]: Page404,
  ["Solution Overview"]: SolutionsOverview,
  ["Solution Detail"]: SolutionDetail,
  ["Services Overview"]: ServicesOverview,
  ["About Us"]: AboutUs,
  ["Blog"]: BlogPost,
  ["Case Study"]: CaseStudyDetail,
  ["Resources Overview"]: ResourcesOverview,
  ["Newsletter CTA Banner"]: CtaNewsletterBanner,
  ["EU projects"]: EuCoFundedProjects,
  ["Simplity Europe Homepage"]: SimplityEuropeHomepage,
}

const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components
