import React from 'react'

import { cutInLines } from '../contentEdit'

type RoadmapProps = {
  title: string;
  steps: { text: string }[];
}

const Roadmap = ({ title, steps }: RoadmapProps) => {

  return (
    <section className="c-roadmap" id="data-assessment">
      {title && <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="row">
              <div className="col-lg-10 col-xl-8 col-xxl-6">
                <div className="c-roadmap__box">
                  <h2 className="c-roadmap__headline">
                    {cutInLines(title)}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      <div className="c-roadmap__mountainWrapper">
        <div className="c-roadmap__mountain"/>
        <div className="container">
          <ol className="c-roadmap__steps">
            {steps.map((step, i) => <li key={step.text}>
              <span className="c-roadmap__stepsCircle">{i + 1}</span>
              <span>{step.text}</span>
            </li>)}
          </ol>
        </div>
      </div>
    </section>
  )
};

export default Roadmap
