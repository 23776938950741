import React from "react"

import StoryblokLink from "../shared/StoryblokLink"

import { Card } from "../../types"

type UseCaseCard = {
  card: Card
}

const UseCaseCard = ({ card }: UseCaseCard) => {
  return (
    <StoryblokLink
      to={card?.ctaLink}
      className="c-useCaseCard d-flex flex-column"
    >
      <div className="c-useCaseCard__hoverWrapper">
        <div
          className="c-useCaseCard__imageWrapper"
          style={{ backgroundImage: `url(${card?.image.filename})` }}
        />

        <div className="c-useCaseCard__content">
          <h4 className="c-useCaseCard__heading">{card?.title}</h4>

          <p className="c-useCaseCard__text">{card?.text}</p>

          <span className="btn-tertiary hover-bcgUnderline">{card?.cta}</span>
        </div>
      </div>
    </StoryblokLink>
  )
}

export default UseCaseCard
