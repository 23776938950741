import React from "react"

import FiftyFiftyBlock from "../shared/FiftyFiftyBlock"

import { FiftyFiftyCardType } from "../../types"

type SolutionCasesProps = {
  title: string
  useCases: FiftyFiftyCardType[]
}

const SolutionCases = ({ title, useCases }: SolutionCasesProps) => {
  const isOdd = number => {
    return !!(number % 2)
  }

  const blocks = useCases?.map((block, i) => (
    <FiftyFiftyBlock
      key={`solution-fifty=${i}`}
      content={block}
      rightAlign={isOdd(i)}
      isLast={i === useCases.length - 1}
    />
  ))

  return (
    <section className="c-solutionCases">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-solutionCases__headlineWrapper">
              <h2 className="section-heading section-heading--mobilexs18">
                {title}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="c-solutionCases__blocks">{blocks}</div>
    </section>
  )
}

export default SolutionCases
