import React, { useCallback, useMemo, useState, useEffect } from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import scrollTo from "gatsby-plugin-smoothscroll"
import { checkLinkUrl } from "../contentEdit"
import { ctaTarget } from "../../types"

export type StoryblokImageProps = {
  to: ctaTarget
  className?: string
  children: React.ReactNode
  pathname?: string
  isInternalAnchor?: boolean
}

const StoryblokLink = ({
  to,
  className,
  children,
  pathname = "",
  isInternalAnchor,
}: StoryblokImageProps) => {
  const [link, setLink] = useState("/")
  let finalLink = null

  useEffect(() => {
    if (to.linktype === "story") {
      const checkedLink = checkLinkUrl(to.cached_url)
      setLink(checkedLink)
    }

    if (isInternalAnchor) setLink(to.cached_url)
  }, [to])

  const renderLinkClassNames = () =>
    classNames({
      active: !!pathname && pathname === link,
      [`${className}`]: !!className,
    })

  if (to.linktype === "story") {
    finalLink = (
      <a className={renderLinkClassNames()} href={link}>
        {children}
      </a>
    )
  } else if (isInternalAnchor) {
    finalLink = (
      <span className={renderLinkClassNames()} onClick={() => scrollTo(link)}>
        {children}
      </span>
    )
  } else {
    finalLink = (
      <a
        href={to.cached_url}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  }

  return finalLink
}

export default StoryblokLink
