import React from "react"
import Layout from "../components/layout/Layout"
import Hero from "../components/shared/Hero"
import TextBlock from "../components/caseStudyDetail/TextBlock"
import Numbers from "../components/caseStudyDetail/Numbers"
import HighlightText from "../components/caseStudyDetail/HighlightText"
import CaseBenefits from "../components/caseStudyDetail/CaseBenefits"
import ThreeBlock from "../components/shared/ThreeBlock"
import CtaBanner from "../components/shared/CtaBanner"

import patternDark from "../assets/images/hero-pattern-dark.svg"

import {
  SeoMetaType,
  HeaderType,
  CaseNumbersType,
  CtaBannerType,
} from "../types"

type CaseStudyDetailProps = {
  seoMeta: SeoMetaType
  pathname: string
  header: HeaderType[]
  sectionName: string
  challengeTitle: string
  challengeText: any
  projectNumbers: CaseNumbersType
  highlightText: string
  sectionName2: string
  objectivesTitle: string
  objectivesText: any
  sectionName3: string
  solutionText: any
  sectionName4: string
  benefitsText: any
  sectionName5: string
  cards: any
  ctaBanner: CtaBannerType
  showChallenge: boolean
  showNumbers: boolean
  showHighlighted: boolean
  showObjective: boolean
  showSolution: boolean
  showBenefits: boolean
}

const CaseStudyDetail = ({
  seoMeta,
  pathname,
  header,
  sectionName,
  challengeTitle,
  challengeText,
  projectNumbers,
  highlightText,
  sectionName2,
  objectivesTitle,
  objectivesText,
  sectionName3,
  solutionText,
  sectionName4,
  benefitsText,
  sectionName5,
  cards,
  ctaBanner,
  showChallenge,
  showNumbers,
  showHighlighted,
  showObjective,
  showSolution,
  showBenefits,
}: CaseStudyDetailProps) => {
  return (
    <Layout
      contentClass="CaseStudyDetailPage"
      seoMeta={seoMeta}
      pathname={pathname}
    >
      <Hero header={header} />
      <TextBlock
        show={showChallenge}
        blockId="challenge"
        sectionName={sectionName}
        title={challengeTitle}
        content={challengeText}
      />
      <Numbers show={showNumbers} content={projectNumbers[0]} />

      <div className="CaseStudyDetailPage__white">
        {(showHighlighted || showObjective || showSolution) && (
          <>
            <img
              src={patternDark}
              className="CaseStudyDetailPage__pattern1"
              alt=""
            />
            <img
              src={patternDark}
              className="CaseStudyDetailPage__pattern2"
              alt=""
            />
          </>
        )}
        <HighlightText show={showHighlighted} text={highlightText} />
        <TextBlock
          show={showObjective}
          blockId="objectives"
          sectionName={sectionName2}
          title={objectivesTitle}
          content={objectivesText}
        />
        <TextBlock
          show={showSolution}
          blockId="solution"
          sectionName={sectionName3}
          content={solutionText}
        />
      </div>

      <CaseBenefits
        show={showBenefits}
        sectionName={sectionName4}
        content={benefitsText}
      />
      <ThreeBlock title={sectionName5} cards={cards} />
      <CtaBanner ctaBanner={ctaBanner} />
    </Layout>
  )
}

export default CaseStudyDetail
