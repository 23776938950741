import React, { useRef } from "react"
import Slider from "react-slick"

import StoryblokImage from "../shared/StoryblokImage"

import { MemberType } from "../../types"

type TeamTestimonialSliderProps = {
  members: MemberType[]
  forwardedRef: React.Ref<ReactNode>
}

const TeamTestimonialSlider = ({
  members,
  forwardedRef,
}: TeamTestimonialSliderProps) => {
  const settings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    dots: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          sldiesToScroll: 1,
        },
      },
    ],
  }

  const slides = members?.map((member, i) => (
    <div key={`member-${i}`} className="c-teamTestimonial__item">
      <div className="c-teamTestimonial__imageWrapper">
        <StoryblokImage image={member?.content.image} />
      </div>

      <p className="c-teamTestimonial__name">{member?.content.name}</p>

      <p className="c-teamTestimonial__position">{member?.content.position}</p>
    </div>
  ))

  return (
    <div className="c-testimonialSlider">
      <Slider ref={forwardedRef} {...settings}>
        {slides}
      </Slider>
    </div>
  )
}

export default TeamTestimonialSlider
