import React from "react"
import classNames from "classnames"
import scrollTo from "gatsby-plugin-smoothscroll"

import { IndustriesCardType } from "../../types"

type TopThreeItemProps = {
  content: IndustriesCardType
  isScroll?: boolean
}

const TopThreeItem = ({ content, isScroll }: TopThreeItemProps) => {
  const renderClassNames = () =>
    classNames("c-topThreeBlock__item", {
      "c-topThreeBlock__item--scroll": isScroll,
    })

  const handleCardClick = () => {
    if (isScroll) scrollTo(content?.ctaLink.cached_url)
  }

  return (
    <div className={renderClassNames()}>
      <div
        className="c-topThreeBlock__hoverWrapper"
        onClick={() => handleCardClick()}
      >
        <div
          className="c-topThreeBlock__image"
          style={{ backgroundImage: `url(${content?.image.filename})` }}
        />

        <div className="c-topThreeBlock__itemContent">
          <h4 className="c-topThreeBlock__itemHeadline">{content?.title}</h4>

          <p className="c-topThreeBlock__itemText">{content?.text}</p>

          {content?.cta && content?.ctaLink.cached_url && (
            <div className="c-topThreeBlock__itemLinkWrapper">
              <span className="c-topThreeBlock__itemLink hover-bcgUnderline">
                {content?.cta}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TopThreeItem
