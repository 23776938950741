import React from "react"

import StoryblokLink from "./StoryblokLink"

import { renderCardHeadline } from "../contentEdit"

import { ThreeCardCaseStudy } from "../../types"

type ThreeBlockCaseStudyProps = {
  full_slug: string
  content: ThreeCardCaseStudy
}

const ThreeBlockCaseStudy = ({
  full_slug,
  content,
}: ThreeBlockCaseStudyProps) => {
  const linkObject = {
    cached_url: full_slug,
    linktype: "story",
  }

  const cardHeadline = renderCardHeadline(
    content?.header[0]?.pageTitle,
    "c-threeBlock__heading"
  )

  return (
    <StoryblokLink
      to={linkObject}
      className="c-threeBlock__item d-flex flex-column"
    >
      <div className="c-threeBlock__hoverWrapper">
        <div
          className="c-threeBlock__imageWrapper"
          style={{
            backgroundImage: `url(${content?.header[0]?.cardThumb?.filename})`,
          }}
        />

        <div className="c-threeBlock__content">
          <span className="c-threeBlock__type">{content?.component}</span>

          {cardHeadline}

          <span className="btn-tertiary hover-bcgUnderline">Read more</span>
        </div>
      </div>
    </StoryblokLink>
  )
}

export default ThreeBlockCaseStudy
